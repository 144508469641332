angular.module('LeasePilot').directive('imageOnLoad', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.on('load', function() {
        scope.$apply(function() {
          scope.adjustImageWidth(element[0]);
        });
      });
    }
  };
});